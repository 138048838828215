import React, { useState } from "react";
import VerticalListItem from "../components/VerticalListItem";

const SearchPage = ({ handleItemClick, showOverlayHeader, numbers }) => {
  const [query, setQuery] = useState("");

  const searchItems = (array, searchTerm) => {
    if (!searchTerm) return array; // Return the original array if no search term is provided

    const lowercasedTerm = searchTerm.toLowerCase();

    return array.filter((item) => {
      return (
        item.name.toLowerCase().includes(lowercasedTerm) ||
        item.description.toLowerCase().includes(lowercasedTerm) ||
        item.author.toLowerCase().includes(lowercasedTerm)
      );
    });
  };

  const filteredItems = searchItems(numbers, query);

  console.log(filteredItems.name);

  const getTopRatedItems = (array, count = 5) => {
    // Create a shallow copy of the original array
    const copiedArray = [...array];

    // Sort by rate in descending order and slice the top `count` items
    return copiedArray
      .sort((a, b) => b.rate - a.rate) // Sort by rate
      .slice(0, count); // Get the top `count` items
  };

  let searchView;
  if (query.length > 0 && filteredItems.length == 0) {
    searchView = (
      <div className="text-white mx-10 text-center my-6 medium text-lg">
        No item found for this search result "{query}"
      </div>
    );
  } else if (query.length > 0) {
    {
      searchView = filteredItems.map((item) => (
        <VerticalListItem
          game={item}
          handleItemClick={handleItemClick}
          isForSearch={true}
        />
      ));
    }
  } else {
    searchView = (
      <div className="text-white mx-10 text-center my-6 medium text-lg">
        Oops! It seems your search bar is feeling a bit lonely!
      </div>
    );
  }

  return (
    <div className="flex  flex-col  relative w-screen h-full bg-black">
      <h1 className="text-white semiBold text-4xl bg-black ml-4 mt-4 tracking-wider">
        Search
      </h1>
      {showOverlayHeader == true && (
        <div class="fixed flex top-0 left-0 right-0 justify-center items-center w-screen h-16 z-40  backdrop-blur-lg m-auto animate-appear">
          <h1 className="text-white medium text-2xl tracking-wider">Search</h1>
        </div>
      )}

      <div className="mb-3 mx-4 mt-2 xl:w-96">
        <input
          type="search"
          className="relative m-0 block w-full min-w-0 flex-auto rounded-xl border bg-gray-800 border-solid border-gray-800 text-white bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
          id="exampleSearch"
          value={query}
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <div className="bg-gray-900">
        <div className="flex flex-row justify-between items-baseline mx-4 mt-4">
          <h2 className="text-white medium text-3xl">Search Result</h2>
        </div>

        {searchView}
      </div>

      <div className="flex flex-row justify-between items-baseline mx-4 mt-4">
        <h2 className="text-white medium text-3xl">Suggested</h2>
      </div>

      {getTopRatedItems(numbers, 4).map((item) => (
        <VerticalListItem game={item} handleItemClick={handleItemClick} />
      ))}

      <div className="h-20"></div>
    </div>
  );
};

export default SearchPage;
