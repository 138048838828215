import logo from "./logo.svg";
import "./App.css";
import AddGames from "./screens/AddGames";
import MainPage from "./screens/MainPage";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./screens/LoginPage";
import { AuthProvider } from "./hook/useAuth";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AddGames />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
