import userIcon from "../assets/res/user.svg";
const Privacy = ({}) => {
  return (
    <div className="p-4 bg-gray-800 rounded-xl mt-4">
      <div className="flex flex-col items-center">
        <img src={userIcon} width="40" height="40" alt="Apps" />
        <div className="text-white medium text-xl mt-2">
          Data not Linked to you
        </div>
        <div className="text-gray-400 medium text-center text-base">
          We value your privacy. This website does not collect, store, or
          process any personal information from our visitors. We do not ask for
          or track names, email addresses, phone numbers, or any other
          identifiable information.
        </div>
      </div>
    </div>
  );
};

export default Privacy;
