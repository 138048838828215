import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import backIcon from "..//assets/res/back_icon.svg";

import Rating from "../components/Rating";
import Privacy from "../components/Privacy";
import PlatformDialogPicker from "../components/PlatformDialogPicker";
import appleIcon from "..//assets/res/apple_white.svg";
import androidIcon from "..//assets/res/android_white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_BASE_URL } from "../utils/Constants";

const InfoBlock = ({ showLeftDivier = false, title, value }) => {
  return (
    <div className="flex flex-row">
      {showLeftDivier && (
        <div className="h-12 w-[0.5px] bg-gray-800 mr-6 mt-1"></div>
      )}
      <div className="flex flex-col items-center  ">
        <div className="text-gray-600 medium tracking-wide  text-center text-xs">
          {title}
        </div>
        <div className="text-gray-300 medium text-base  text-center tracking-wide">
          {value}
        </div>
      </div>
      <div className="h-12 w-[0.5px] bg-gray-800 ml-6 mt-1"></div>
    </div>
  );
};

const InfoBlockPlatform = ({ title, isForAndroid, isForIos }) => {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col items-center  ">
        <div className="text-gray-600 medium tracking-wide  text-center text-xs">
          {" "}
          {title}
        </div>
        <div className="flex flex-row justify-center mt-1">
          {isForAndroid && <img src={androidIcon} className="h-5 w-5" />}
          {isForIos && <img src={appleIcon} className="h-5 w-5 ml-2" />}
        </div>
      </div>
      <div className="h-12 w-[0.5px] bg-gray-800 ml-6 mt-1"></div>
    </div>
  );
};

const DetailsPage = ({ fromPage, selectedGame, handleClose, numbers }) => {
  const [showDialog, setShowDialog] = useState(false);

  if (!selectedGame) {
    return;
  }

  const {
    name,
    description,
    image_path,
    rate,
    size,
    author,
    download,
    isForAndroid,
    isForIos,
    gameKeywords,
  } = selectedGame;

  console.log(isForAndroid);
  console.log(isForIos);

  function dismissDialog() {
    setShowDialog(false);
  }

  function openDialog() {
    setShowDialog(true);
  }

  console.log(gameKeywords);
  return (
    <>
      <Helmet>
        <title> {name}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={gameKeywords} />
      </Helmet>

      <div className="bg-black  flex flex-col p-4 h-full">
        <div className="flex flex-row items-center " onClick={handleClose}>
          <img
            className="h-8 w-8 object-cover object-center -ml-2"
            src={backIcon}
            alt="nature image"
          />
          <div className="text-white semiBold text-2xl pt-0.5">{fromPage}</div>
        </div>
        {/* HEAD START */}
        <div className="flex flex-row mt-4">
          <div className="h-24 w-24 rounded-xl bg-gray-900 flex-none overflow-hidden border-[0.5px]">
            <img
              className="h-24 w-24 object-cover object-center"
              src={GET_BASE_URL() + "images/" + image_path}
              alt="nature image"
            />
          </div>
          <div className="flex flex-col justify-between ml-4 my-1">
            <div className="text-white medium text-2xl line-clamp-2">
              {name}
            </div>

            <div
              className="rounded-full w-[70px] text-white medium text-lg bg-blue-800  pl-4 pt-0.5 tracking-widest flex-wrap"
              onClick={openDialog}
            >
              GET
            </div>
          </div>
        </div>
        {/* HEAD END */}

        <div className="h-[0.5px] bg-gray-800 mt-6" />

        <div className="flex flex-row mt-4  bg-red overflow-scroll no-scrollbar ">
          <InfoBlock showLeftDivier={true} title="RATING" value={rate} />

          <div className="w-[0.5px] bg-gray-800 my-2 mx-4"></div>
          <InfoBlock title="DEVELOPER" value={author} />
          <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
          <InfoBlockPlatform
            title="PLATFORM"
            isForAndroid={isForAndroid}
            isForIos={isForIos}
          />
          <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
          <InfoBlock title="DOWNLOAD" value={download + "K"} />
          <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
          <InfoBlock title="SIZE" value={size} />
          <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
        </div>

        <div className="h-[0.5px] bg-gray-800 mt-4" />

        <div className="medium text-m text-white tracking-wide mt-4">
          {description}
        </div>

        <Rating rate={rate} />
        <Privacy />

        <div className="semiBold text-wider text-2xl text-white mt-4">
          You Might Also Like
        </div>

        {numbers.map((item) => (
          <div className="flex flex-col mt-4">
            <div className=" flex flex-row bg-gray-900 rounded-xl pr-4">
              <div className="h-20 w-20 rounded-xl  flex-none overflow-hidden">
                <LazyLoadImage
                  className="h-20 w-20 object-cover object-center"
                  src={GET_BASE_URL() + "images/" + item.image_path}
                  alt={name}
                />
              </div>
              <div className="flex flex-col justify-center ml-2 flex-none">
                <div className="text-white medium text-xl truncate w-60">
                  {item.name}
                </div>
                <div className="flex flex-row  mt-1 mb-1 ">
                  {isForAndroid && (
                    <img src={androidIcon} className="h-4 w-4 mr-2" />
                  )}
                  {isForIos && <img src={appleIcon} className="h-4 w-4 " />}
                </div>

                <div className="rounded-md bg-blue-400 w-[30px] pl-[5px] text-white text-xs">
                  Ad
                </div>
              </div>
            </div>
          </div>
        ))}

        {showDialog && (
          <PlatformDialogPicker
            selectedGame={selectedGame}
            onBackEvent={dismissDialog}
          />
        )}
      </div>
    </>
  );
};

export default DetailsPage;
