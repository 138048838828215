import { dark } from "@mui/material/styles/createPalette";
import axios from "axios";

import React, { useState } from "react";
import { GET_BASE_URL } from "../utils/Constants";

const AddGames = () => {
  const [isForIosCheck, setIsForIosCheck] = useState(false);
  const [showMirrorLinks, setShowMirrorLinks] = useState(false);
  const [isForAndroidCheck, setIsForAndroidCheck] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");

  const [apkFile, setApkFile] = useState(null);
  const [apkName, setApkName] = useState("");

  const [ipaFile, setIpaFile] = useState(null);
  const [ipaName, setIpaName] = useState("");

  const handleSubmitNew = async (event) => {
    event.preventDefault();

    var {
      name,
      description,
      author,
      isForIos,
      isForAndroid,
      category,
      size,
      rate,
      mirror_1,
      mirror_2,
      download,
      showMirrorLinksId,
      gameKeywords,
    } = document.forms[0];

    const sendData = {
      name: name.value,
      description: description.value,
      image_path: imageName,
      apk_path: apkName,
      ipa_path: ipaName,
      rate: rate.value,
      download: download.value,
      author: author.value,
      isForIos: isForIosCheck ? 1 : 0,
      isForAndroid: isForAndroidCheck ? 1 : 0,
      size: size.value,
      category: category.value,
      mirror_1: mirror_1.value,
      mirror_2: mirror_2.value,
      showMirrorLinks: showMirrorLinks ? 1 : 0,
      gameKeywords: gameKeywords.value,
    };

    if (imageName.length == 0) {
      alert("Image not upload");
      return;
    }

    if (apkName.length == 0) {
      alert("Apk not upload");
      return;
    }

    if (ipaName.length == 0) {
      alert("IPA not upload");
      return;
    }

    //here add axios call
    console.log(sendData);
    axios
      .post(GET_BASE_URL() + "insert_data.php", sendData)
      .then((result) => {
        console.log("sadasdasd");
        console.log(result);
        if (result.status === 200) {
          alert("Success adding data");
        } else {
          alert("Error adding data");
        }
      })
      .catch(function (error) {
        console.log("sadasdasd catch");
        console.log(error.toJSON());
      });
  };

  const handleImageFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setImageFile(file);
  };

  const handleApkFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setApkFile(file);
  };

  const handleIPAFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setIpaFile(file);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image_path", imageFile);

    axios
      .post(GET_BASE_URL() + "upload.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.length > 0) {
          console.log(result.data);
          alert("Image upload");
          setImageName(result.data);
        } else {
          alert("Error uploading image");
        }
      });
  };

  const handleApkUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("apk_path", apkFile);

    axios
      .post(GET_BASE_URL() + "upload_apk.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.length > 0) {
          console.log(result.data);
          alert("Apk upload");
          setApkName(result.data);
        } else {
          alert("Error uploading apk");
        }
      });
  };

  const handleIPAUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("ipa_path", ipaFile);

    axios
      .post(GET_BASE_URL() + "upload_ipa.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.length > 0) {
          alert("IPA upload with name:" + result.data);
          setIpaName(result.data);
        } else {
          alert("Error uploading IPA");
        }
      });
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white">
      <div>
        <label className="block text-lg font-bold mb-1" htmlFor="image_path">
          Image Path
        </label>
        <div className="flex items-center">
          <input
            type="file"
            name="image_path"
            id="image_path"
            onChange={handleImageFile}
            className="w-full p-3 border rounded-lg"
            accept="image/*"
          />
          {imageName.length > 0 ? (
            <div className="rounded-3xl bg-green-500 h-8 w-8 ml-2"></div>
          ) : (
            <button
              onClick={(e) => handleImageUpload(e)}
              className={
                "bg-blue-500 p-3 text-white rounded-lg hover:bg-blue-600 transition"
              }
            >
              Upload
            </button>
          )}
        </div>
      </div>
      <div>
        <label className="block text-lg font-bold mb-1" htmlFor="apk_path">
          APK Path
        </label>

        <div className="flex items-center">
          <input
            type="file"
            name="apk_path"
            id="apk_path"
            onChange={handleApkFile}
            className="w-full p-3 border rounded-lg"
            accept=".apk"
          />
          {apkName.length > 0 ? (
            <div className="rounded-3xl bg-green-500 h-8 w-8 ml-2"></div>
          ) : (
            <button
              onClick={(e) => handleApkUpload(e)}
              className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Upload
            </button>
          )}
        </div>
      </div>
      <div>
        <label className="block text-lg font-bold mb-1" htmlFor="ipa_path">
          IPA Path
        </label>
        <div className="flex items-center">
          <input
            type="file"
            name="ipa_path"
            id="ipa_path"
            onChange={handleIPAFile}
            className="w-full p-3 border rounded-lg"
            accept=".mobileconfig"
          />
          {ipaName.length > 0 ? (
            <div className="rounded-3xl bg-green-500 h-8 w-8 ml-2"></div>
          ) : (
            <button
              onClick={(e) => handleIPAUpload(e)}
              className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Upload
            </button>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmitNew} className="space-y-4">
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter name"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="description">
            Description
          </label>
          <textarea
            name="description"
            id="description"
            placeholder="Enter description"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="author">
            Author
          </label>
          <input
            type="text"
            name="author"
            id="author"
            placeholder="Enter author"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="block text-lg font-bold mr-4">
            Operating System
          </label>
          <div>IOS</div>
          <input
            type="checkbox"
            name="isForIos"
            id="isForIos"
            checked={isForIosCheck}
            onChange={() => setIsForIosCheck(!isForIosCheck)}
            placeholder="1 or 0"
            className="w-full p-3 border rounded-lg"
          />
          <div>Android</div>
          <input
            type="checkbox"
            name="isForAndroid"
            id="isForAndroid"
            onChange={() => setIsForAndroidCheck(!isForAndroidCheck)}
            checked={isForAndroidCheck}
            placeholder="1 or 0"
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="category">
            Category
          </label>
          <input
            type="number"
            name="category"
            id="category"
            placeholder="Enter category"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="size">
            Size
          </label>
          <input
            type="text"
            name="size"
            id="size"
            placeholder="Enter size"
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="rate">
            Rate
          </label>
          <input
            type="number"
            name="rate"
            id="rate"
            step="any"
            placeholder="Enter rate"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="mirror_1">
            Mirror 1
          </label>
          <input
            type="text"
            name="mirror_1"
            id="mirror_1"
            placeholder="Enter mirror 1"
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="mirror_2">
            Mirror 2
          </label>
          <input
            type="text"
            name="mirror_2"
            id="mirror_2"
            placeholder="Enter mirror 2"
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-bold mb-1" htmlFor="download">
            Download
          </label>
          <input
            type="number"
            name="download"
            id="download"
            placeholder="Enter download count"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>
        <div className="flex items-center mb-4">
          <label className="block text-lg font-bold mr-4">Mirror Links</label>
          <div>Show</div>
          <input
            type="checkbox"
            name="showMirrorLinksId"
            id="showMirrorLinksId"
            checked={showMirrorLinks}
            onChange={() => setShowMirrorLinks(!showMirrorLinks)}
            placeholder="1 or 0"
            className="w-full p-3 border rounded-lg"
          />
        </div>

        <div>
          <label
            className="block text-lg font-bold mb-1"
            htmlFor="gameKeywords"
          >
            Keywords
          </label>
          <textarea
            name="gameKeywords"
            id="gameKeywords"
            placeholder="Enter game keywords"
            required
            className="w-full p-3 border rounded-lg"
          />
        </div>

        <button
          type="submit"
          className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddGames;
