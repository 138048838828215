import React from "react";
import classNames from "classnames"; // Make sure to install this package or implement your own logic
import gamesIcon from "../assets/res/game.svg";
import searchIcon from "../assets/res/search.svg";
import appsIcon from "../assets/res/apps.svg";

const BottomNav = ({ page, setPage }) => {
  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-20 backdrop-blur-lg">
      <div className="grid h-full grid-cols-3 mx-auto">
        <div
          className="flex justify-center items-center"
          onClick={() => setPage("Games")}
        >
          <div className="flex flex-wrap flex-col place-items-center">
            <div
              className={classNames("px-6 py-0.5 rounded-full mb-0.5", {
                "bg-blue-400": page === "Games",
              })}
            >
              <img src={gamesIcon} width="22" height="22" alt="Games" />
            </div>
            <button className="flex-1 text-center medium text-l text-white">
              Games
            </button>
          </div>
        </div>

        <div
          className="flex justify-center items-center"
          onClick={() => setPage("Apps")}
        >
          <div className="flex flex-wrap flex-col place-items-center">
            <div
              className={classNames("px-6 py-0.5 rounded-full mb-0.5", {
                "bg-blue-400": page === "Apps",
              })}
            >
              <img src={appsIcon} width="22" height="22" alt="Apps" />
            </div>
            <button className="flex-1 text-center medium text-l text-white">
              Apps
            </button>
          </div>
        </div>

        <div
          className="flex justify-center items-center"
          onClick={() => setPage("Search")}
        >
          <div className="flex flex-wrap flex-col place-items-center">
            <div
              className={classNames("px-6 py-0.5 rounded-full mb-0.5", {
                "bg-blue-400": page === "Search",
              })}
            >
              <img src={searchIcon} width="22" height="22" alt="Search" />
            </div>
            <button className="flex-1 text-center medium text-l text-white">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
