import React, { useEffect, useState } from "react";
import classNames from "classnames";
import axios from "axios";
import GamesPage from "./GamesPage";
import AppsPage from "./AppsPage";
import BottomNav from "../components/BottomNavigationBar";
import getRandomElements from "../utils/Helpers";
import { Container, CircularProgress, Drawer, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";

import SearchPage from "./SearchScreen";
import DetailsPage from "./DetailsPage";
import { GET_BASE_URL } from "../utils/Constants";

const MainPage = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState("Games");
  const [showOverlayHeader, setShowOverlayHeader] = useState(false);

  const handleScrollTop = () => {
    const position = window.scrollY;

    if (position > 10) {
      setShowOverlayHeader(true);
    } else {
      setShowOverlayHeader(false);
    }
  };

  const fetchData = async () => {
    try {
      // Example API call (you can replace this with your actual API)
      const response = await axios.post(GET_BASE_URL() + "get_game.php");

      setItems(response.data);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    window.addEventListener("scroll", handleScrollTop, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-200 fixed inset-0 z-50">
        <CircularProgress />
      </div>
    );
  }

  console.log(items);

  const renderPage = () => {
    switch (page) {
      case "Games":
        return (
          <GamesPage
            handleItemClick={handleItemClick}
            showOverlayHeader={showOverlayHeader}
            numbers={items}
          />
        );
      case "Apps":
        return (
          <AppsPage
            handleItemClick={handleItemClick}
            showOverlayHeader={showOverlayHeader}
            gamesData={items}
          />
        );
      case "Search":
        return (
          <SearchPage
            handleItemClick={handleItemClick}
            showOverlayHeader={showOverlayHeader}
            numbers={items}
          />
        );
      default:
        return (
          <div className="flex items-center justify-center h-screen">
            404 Not Found
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Apk Playgorund</title>
        <meta
          name="description"
          content="android and ios games and mods apk and ipa file"
        />
      </Helmet>
      <div className="flex flex-col align-middle bg-black">
        <div className="">{renderPage()}</div>

        <Drawer anchor={"bottom"} open={open} onClose={handleClose}>
          <DetailsPage
            fromPage={page}
            selectedGame={selectedItem}
            handleClose={handleClose}
            numbers={getRandomElements(items, 3)}
          />
        </Drawer>

        <BottomNav page={page} setPage={setPage} />
      </div>
    </>
  );
};

export default MainPage;
