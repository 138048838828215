export const BASE_URL = "https://apkplayground.store/";
export const LOCAL_HOST = "http://localhost/max_games/";

export function GET_BASE_URL() {
  if (useDev) {
    return LOCAL_HOST;
  } else {
    return BASE_URL;
  }
}

const useDev = false;
