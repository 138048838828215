import React, { useState, useEffect } from "react";

import HorizontalListItem from "../components/HorizontalListItem";
import VerticalListItem from "../components/VerticalListItem";

const AppsPage = ({ handleItemClick, showOverlayHeader, gamesData }) => {
  const [filteredGames, setFilteredGames] = useState([]);

  useEffect(() => {
    // Filter the games and copy the result to filteredGames state
    const newFilteredGames = gamesData.filter((game) => game.category === "2");
    setFilteredGames(newFilteredGames.slice()); // Create a copy using slice()
  }, [gamesData]);

  return (
    <div className="flex  flex-col  relative w-screen">
      <h1 className="text-white semiBold text-4xl bg-black ml-4 mt-4 tracking-wider">
        Apps
      </h1>
      {showOverlayHeader == true && (
        <div class="fixed flex top-0 left-0 right-0 justify-center items-center w-screen h-16 z-40 backdrop-blur-lg m-auto animate-appear">
          <h1 className="text-white medium text-2xl tracking-wider">Apps</h1>
        </div>
      )}
      <h2 className="text-blue-600 medium bg-black mx-4 mt-2 tracking-wider">
        NOW AVAILABLE
      </h2>
      <h2 className="text-white medium text-2xl bg-black mx-4 tracking-wide">
        Top Rated
      </h2>
      <div className="flex overflow-x-scroll pb-4 hide-scroll-bar mt-2">
        <div className="flex flex-nowrap ml-2 ">
          {filteredGames
            .filter((game) => game.rate > 4)
            .map((item) => (
              <HorizontalListItem
                game={item}
                handleItemClick={handleItemClick}
              />
            ))}
        </div>
      </div>

      <div className=" h-px bg-gray-700 mx-4" />

      <div className="flex flex-row justify-between items-baseline mx-4 mt-4">
        <h2 className="text-white medium text-3xl">App Essentials</h2>
        <h3 className="text-blue-500 medium text-xl">See All</h3>
      </div>

      {filteredGames.map((item) => (
        <VerticalListItem game={item} handleItemClick={handleItemClick} />
      ))}

      <div className="h-20"></div>
    </div>
  );
};

export default AppsPage;
